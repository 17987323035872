import React from "react";

const Stats = ({ teams }) => {
  const totalPoints = teams.reduce((total, teams) => total + teams.score, 0);

  return (
    <div className="stats">
      <p>Total Points: {totalPoints}</p>
      <p
        className={
          totalPoints > 0 ? (totalPoints % 7 === 0 ? "switch-sides" : " ") : " "
        }
      >
        Switch sides
      </p>
    </div>
  );
};

export default Stats;
