import React, { useState } from "react";
import Header from "./components/Header";
import Team from "./components/Team";
import AddTeamForm from "./components/AddTeamForm";

const App = () => {
  const [teams, setTeams] = useState([]);
  // const [results, setResults] = useState([]);
  const [switchSides, setSwitchSides] = useState(false);

  const handleAddTeam = (name) => {
    setTeams([
      ...teams,
      {
        name,
        score: 0,
      },
    ]);
  };

  const highestScore = () => {
    const scores = teams.map((team) => team.score);
    const highestScore = Math.max(...scores);

    if (highestScore) {
      return highestScore;
    } else {
      return null;
    }
  };

  const handleScoreChange = (index, delta) => {
    // New 'teams' array – a copy of the previous `teams` state
    const updatedTeams = JSON.parse(JSON.stringify(teams));

    // A copy of the teams object we're targeting
    const updatedTeam = { ...updatedTeams[index] };

    // Update the target team score
    updatedTeam.score += delta;

    // Update the 'teams' array with the target team latest score
    updatedTeams[index] = updatedTeam;

    // Update the state
    setTeams(updatedTeams);
  };

  const handleSwitchSides = () => {
    setSwitchSides(!switchSides);
  };

  // const handleEndSet = () => {
  //   // Make hard copy of state
  //   const updatedTeams = JSON.parse(JSON.stringify(teams));
  //   let scores = [];
  //   let winner = null;

  //   if (updatedTeams[0].score > updatedTeams[1].score) {
  //     winner = 0;
  //   } else {
  //     winner = 1;
  //   }

  //   updatedTeams.forEach((team, index) => {
  //     scores.push({
  //       score: team.score,
  //       winner: Boolean(winner === index),
  //     });
  //     team.score = 0;
  //   });

  //   setResults([...results, scores]);
  //   setTeams(updatedTeams);
  // };

  const handleReset = () => {
    setTeams([]);
  };

  return (
    <>
      <div className="scoreboard">
        <div className="scoreboard-inner">
          <Header title="VolleyScore" teams={teams} />
          <div
            className="teams"
            style={{ flexDirection: switchSides && "row-reverse" }}
          >
            {teams.map((team, index) => (
              <Team
                key={index}
                name={team.name}
                score={team.score}
                // results={results}
                index={index}
                changeScore={handleScoreChange}
                isHighScore={team.score === highestScore()}
              />
            ))}
          </div>
          {teams.length < 2 ? (
            <AddTeamForm addTeam={handleAddTeam} />
          ) : (
            <div className="scoreboard-footer">
              <button onClick={handleSwitchSides}>Switch sides</button>
              {/* <button onClick={handleEndSet}>End set</button> */}
              <button onClick={handleReset}>Reset</button>
            </div>
          )}
        </div>
        {/* {results.length > 0 && (
          <div className="scoreboard-sets">
            {teams.map((team, index) => {
              return (
                <div key={index} className="scoreboard-sets-team">
                  <span key={index} className="scoreboard-sets-teamname">
                    {team.name}
                  </span>
                  {results.map((set, i) => {
                    return (
                      <span
                        className={`set ${set[index].winner && "set-won"}`}
                        key={i}
                      >
                        {set[index].score}
                      </span>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )} */}
      </div>
    </>
  );
};

export default App;
