import React from "react";
import Counter from "./Counter";
import Icon from "./Icon";

const Team = ({ name, index, score, _results, changeScore, isHighScore }) => {
  // const numberOfSetsWon = results.reduce(function (sums, set) {
  //   sums[set[index].winner] = (sums[set[index].winner] || 0) + 1;
  //   return sums;
  // }, {});

  return (
    <div className="team">
      <span className="team-name">
        <Icon isHighScore={isHighScore} />
        {name}
        {/* <span className="team-sets">{numberOfSetsWon[index]}</span> */}
      </span>

      <Counter score={score} changeScore={changeScore} index={index} />
    </div>
  );
};

export default Team;
