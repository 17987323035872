import React, { useState } from "react";

const DEFAULT_TEAM_VALUE = { name: "" };

const AddTeamForm = ({ addTeam }) => {
  const [team, setTeam] = useState(DEFAULT_TEAM_VALUE);

  const handleValueChange = (e) => {
    setTeam({ name: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addTeam(team.name);
    setTeam(DEFAULT_TEAM_VALUE);
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        onChange={handleValueChange}
        placeholder="Enter a team name"
        value={team.name}
      />

      <input type="submit" value="Add team" />
    </form>
  );
};
export default AddTeamForm;
